import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Layouts
import { CommonLayoutComponent } from './common/common-layout.component';
import { AuthenticationLayoutComponent } from './common/authentication-layout.component';

export const AppRoutes: Routes = [
    {
        path: '',
        redirectTo: 'apps/apps-list',
        pathMatch: 'full',
    },
    {
        path: '',
        component: CommonLayoutComponent,
        children: [
          {
            path: 'account',
            loadChildren: () => import('./account/account.module').then(m => m.AccountModule)
          },
          {
              path: 'dashboard',
              loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
          },
          {
            path: 'apps',
            loadChildren: () => import('./apps/apps.module').then(m => m.AppsModule)
          },
          {
            path: 'schedule',
            loadChildren: () => import('./sections/schedule/schedule.module').then(m => m.ScheduleModule)
          },
          {
            path: 'my-schedule',
            loadChildren: () => import('./sections/my-schedule/my-schedule.module').then(m => m.MyScheduleModule)
          },
          {
            path: 'content',
            loadChildren: () => import('./sections/content/content.module').then(m => m.ContentModule)
          },
          {
            path: 'images',
            loadChildren: () => import('./sections/images/images.module').then(m => m.ImagesModule)
          },
          {
            path: 'feed',
            loadChildren: () => import('./sections/feed/feed.module').then(m => m.FeedModule)
          },
          {
            path: 'bookmarks',
            loadChildren: () => import('./sections/bookmarks/bookmarks.module').then(m => m.BookmarksModule)
          },
          {
            path: 'people',
            loadChildren: () => import('./sections/people/people.module').then(m => m.PeopleModule)
          },
          {
            path: 'exhibitor',
            loadChildren: () => import('./sections/exhibitor/exhibitor.module').then(m => m.ExhibitorModule)
          },
          {
            path: 'sponsor',
            loadChildren: () => import('./sections/sponsor/sponsor.module').then(m => m.SponsorModule)
          },
          {
            path: 'location',
            loadChildren: () => import('./sections/maps/maps.module').then(m => m.MapsModule)
          },
          {
            path: 'notification',
            loadChildren: () => import('./sections/notification/notification.module').then(m => m.NotificationModule)
          },
          {
            path: 'social-media',
            loadChildren: () => import('./sections/social-address/social-address.module').then(m => m.SocialAddressModule)
          }
        ]
    },
  {
    path: '',
    component: AuthenticationLayoutComponent,
    children: [
      {
        path: 'manager',
        loadChildren: () => import('./manager/manager.module').then(m => m.ManagerModule)
      }
    ]
  }
];

